.login-title {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #060438;
}

.login-block-auth {
    padding: 0;
}

.login-block-registration-text {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #060438;
}

.block-alt.registration-block-text-explain-inner, .login-block-registration {
    background-color: #E8ECF5;

    .title {
        font-family: "Inter";
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
        color: #060438;
    }

    .new-user-text {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        color: #060438;
    }
}
