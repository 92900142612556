.site-head {
    position: relative;

    &-menu-col {
        display: flex;
        justify-content: flex-end;

        &-item {
            display: flex;
            align-items: center;
        }

        &-item + &-item {
            margin-left: 40px;
        }
    }
}

.menu-header {
    margin: 0 -20px;
    top: auto;
    display: flex;

    &:before, &:after {
        content: none;
    }

    &-item {
        margin: 0 20px;
    }

    &-link {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: #060438;
        text-decoration: none;

        &:hover {
            color: #9594A9;
            text-decoration: none;
        }
    }
}

.toolbar-header {
    margin: 0 -20px;
    display: flex;
    background-color: transparent;

    &-item {
        margin: 0 20px;
    }

    &-link {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: #004CFE;
        text-decoration: none;

        &:hover {
            color: #4A7FFC;
            text-decoration: none;
        }
    }
}
