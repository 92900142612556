.err {
    &-container {
        margin: 15px 0;
        text-align: center;
    }

    &-code {
        margin: 0;
        font-family: 'Dela Gothic One';
        font-style: normal;
        font-weight: 400;
        font-size: 36px;
        line-height: 52px;
        text-align: center;
        color: #060438;
    }

    &-reasons {
        p {
            margin: 8px auto 0;
            max-width: 320px;
        }

        .btn {
            margin: 20px 0 0;
        }
    }
}
