.filter-triggerWrap + .requests {
    margin-top: 40px;
}
.request {
    &s {
        display: flex;
        flex-wrap: wrap;

        &-filter {
            flex: 0 0 280px;
            width: 280px;
            max-width: 280px;
        }

        &-content {
            padding-left: 20px;
            flex: 0 0 calc(100% - 280px);
            width: calc(100% - 280px);
            max-width: calc(100% - 280px);
            box-sizing: border-box;
        }
    }

    &-list {
        margin: 0 -10px -20px;
        display: flex;
        flex-wrap: wrap;
    }

    &-list &-card {
        margin-right: 10px;
        margin-left: 10px;
        flex: 0 0 calc(50% - 20px);
        width: calc(50% - 20px);
        max-width: calc(50% - 20px);
    }

    &-card {
        margin-bottom: 20px;
        padding: 20px;
        background: #FFFFFF;
        border: 1px solid #E2E3E4;
        border-radius: 4px;
        box-sizing: border-box;
        text-decoration: none !important;

        & > div {
            margin-bottom: 8px;

            &:last-child {
                margin-bottom: 8px;
            }
        }

        &__row {
            display: flex;
            align-items: center;

            .grade {
                margin-right: 12px;
            }
        }

        &__title {
            font-family: 'Tahoma';
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 19px;
            color: #060438;
        }

        &__id {
            font-family: 'Tahoma';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;
            color: #060438;
        }

        &__duration {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;
            color: #060438;
        }

        &__description {
            display: -webkit-box;
            overflow: hidden;
            text-overflow: ellipsis;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3;
        }
    }

    &-paginationWrap {
        margin-top: 20px;
    }

    &-paginationBtnMore {
        margin-top: 56px;
        width: 100%;
    }

    &-details {
        margin-top: 46px;
        display: flex;

        &__preview {
            flex: 0 0 280px;
            width: 280px;
            max-width: 280px;

            &Item {
                margin-bottom: 12px;

                &:last-child {
                    margin-bottom: 0;
                }
            }

            .btn {
                padding: 16px;
                width: 100%;
            }
        }

        &__preview & {
            &__block {
                &Title {
                    font-family: 'Inter';
                    font-style: normal;
                    font-weight: 700;
                    font-size: 14px;
                    line-height: 20px;
                    color: #060438;
                }

                &Description {
                    font-family: 'Inter';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 20px;
                    color: #060438;
                }
            }
        }

        &__title {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 700;
            font-size: 24px;
            line-height: 29px;
            color: #060438;
        }

        &__id {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 24px;
            line-height: 29px;
            color: #060438;
        }

        &__description {
            padding-left: 20px;
            flex: 0 0 calc(100% - 280px);
            width: calc(100% - 280px);
            max-width: calc(100% - 280px);
            box-sizing: border-box;

            &Content {
                width: 580px;
                max-width: 100%;
            }
        }

        &__description & {
            &__block {
                margin-bottom: 32px;

                &:last-child {
                    margin-bottom: 0;
                }

                &Title {
                    font-family: 'Inter';
                    font-style: normal;
                    font-weight: 700;
                    font-size: 20px;
                    line-height: 24px;
                    color: #060438;
                }

                &Description {
                    margin-top: 12px;
                }
            }
        }
    }
}
