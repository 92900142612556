.staticpage-block {
    margin: 40px 0;
    display: flex;

    &__text {
        padding-right: 10px;
        flex: 0 0 50%;
        width: 50%;
        max-width: 50%;

        h1 {
            margin-bottom: 40px;
        }

        p {
            margin-bottom: 12px;

            &.text-indent {
                text-indent: 2em;
            }
        }

        &.politika-obrabotki {
            p {
                margin-bottom: 12px;

                &.text-indent {
                    text-indent: 2em;
                }
            }

            h2 {
                font-weight: bold;
            }

            ul {
                margin-bottom: 12px;

                li {
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 24px;
                }
            }
        }
    }

    &__underTitle {
        margin-bottom: 12px;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
        color: #060438;
    }

    &__image {
        padding-left: 10px;
        flex: 0 0 50%;
        width: 50%;
        max-width: 50%;
        text-align: center;
    }
   
}

.static-block {
    .mr-20 {
        margin-right: 20px;
    }
}

.staticpage-content {
    &__item {
        margin-top: 20px;
    }

    &__title {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 28px;
        color: #9594A9;
    }

    &__text {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 28px;
        color: #060438;

        a {
            color: inherit;
        }
    }
}
