.site-footer {
    padding: 12px 0 40px;
    z-index: 15;

    .copyright-block {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        color: #9594A9;
    }

    .footer-menu {
        &-link {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;
            color: #9594A9;

            &:hover {
                color: #9594A9;
                text-decoration: none;
            }
        }
    }
}
