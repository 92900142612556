.mainBanner {
    position: relative;
    display: flex;
    min-height: 100vh;
    margin-top: -60px;
    margin-bottom: -70px;

    &-bg {
        position: relative;
        padding: 100px 0;
        flex: 0 0 100%;
        width: 100%;
        max-width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        &:before {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 50%;
            background-color: #E8ECF5;
            background-image: url("../../images/mainBanner-vector.svg");
            background-repeat: no-repeat;
            background-position: top 115px left 30px;
        }
    }

    &-images {
        position: relative;
        margin-left: auto;
        width: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &-content {
        padding: 100px 0;
        width: 50%;

        &Wrap {
            position: relative;
            left: -100%;
            flex: 0 0 100%;
            width: 100%;
            max-width: 100%;
            display: flex;
            align-items: center;
        }

        h1 {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 800;
            font-size: 42px;
            line-height: 51px;
            color: #060438;
        }

        p {
            margin-top: 16px;
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 28px;
            color: #060438;
        }

        .btn {
            margin-top: 40px;
        }
    }
}
