.catalog-filter {
    &-header {
        display: none;
    }

    &-content {
        border: none;
    }

    &-block {
        margin-bottom: 32px;

        &-title {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 19px;
            color: #060438;
        }

        &-header {
            padding: 0;
            border: none;

            &:after {
                color: #060438;
            }
        }

        &-content {
            padding: 12px 0 0;
            border: none;

            &-checkbox {
                margin-left: 0;

                label {
                    .custom-input {
                        &-checkbox {
                            position: relative;
                            width: 16px;
                            height: 16px;
                            border: 1px solid #C5C5CF;
                            border-radius: 2px;
                            box-sizing: border-box;
                            box-shadow: none !important;
                            background: #FFFFFF;

                            &:after {
                                position: absolute;
                                top: 50%;
                                left: 50%;
                                width: 100%;
                                height: 100%;
                                display: block;
                                background-image: url("../../../../images/checked.svg") !important;
                                background-repeat: no-repeat;
                                background-position: center;
                                transform: translate(-50%, -50%);
                                opacity: 0;
                            }
                        }

                        &-native:checked ~ .custom-input-checkbox {
                            background: #004CFE;
                            border-color: #004CFE;

                            &:after {
                                opacity: 1;
                            }
                        }

                        &-text {
                            font-family: 'Inter';
                            font-style: normal;
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 17px;
                            color: #060438;
                        }
                    }
                }
            }
        }
    }

    &-footer {
        margin: 0;
        padding: 0;
        border: none;
        
        & > input {
            margin: 0 0 10px;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}
