body {
    font-family: 'Inter', sans-serif;
}

.container {
    max-width: 75rem;
}

h1 {
    margin: 0;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 800;
    font-size: 30px;
    line-height: 36px;
    color: #060438;
}

.page-title {
    margin-bottom: 32px;
}

p {
    margin: 0;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #060438;
}

a, .link {
    color: #004CFE;

    &:hover {
        color: #4A7FFC;
    }
}

ul {
    margin: 0;
    padding-left: 20px;
}

.btn {
    display: inline-flex;
    justify-content: center;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: #FFFFFF;
    text-transform: none;

    &-submit, &-buy {
        padding: 16px 40px;
        background: #004CFE;
        border-radius: 4px;

        &:hover {
            background: #4A7FFC;
        }
    }

    &-action {
        padding: 16px 40px;
        background: #E8E8EB;
        color: #004CFE;

        &:hover {
            color: #004CFE;
            background: #DEDDE3;
        }
    }
}

.form-field-name {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
    color: #676A6C;
}

.form-field-name,
.form-field-input {
    margin: 0;
}

.row.form-field-control {
    margin-bottom: 24px;
}

input[type=text],
input[type=password],
input[type=search],
input[type=email],
input[type=number],
input[type=tel],
input[type=url],
textarea {
    padding: 15px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
    color: #676A6C;
    background: #FFFFFF;
    border: 1px solid #C3C3C3;
    border-radius: 4px;
}

.custom-input-native[type=checkbox] {
    & ~ .custom-input-checkbox {
        position: relative;
        width: 16px;
        height: 16px;
        border: 1px solid #C5C5CF;
        border-radius: 2px;
        box-sizing: border-box;
        box-shadow: none !important;
        background: #FFFFFF;

        &:after {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 100%;
            height: 100%;
            display: block;
            background-image: url("../images/checked.svg") !important;
            background-repeat: no-repeat;
            background-position: center;
            transform: translate(-50%, -50%);
            opacity: 0;
        }
    }

    &:checked ~ .custom-input-checkbox {
        background: #004CFE;
        border-color: #004CFE;

        &:after {
            opacity: 1;
        }
    }

    & ~ .custom-input-text {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        color: #060438;
    }
}

.flatpickr-custom__toggle {
    background: #004CFE;
    color: #FFFFFF;
    border: 1px solid #004CFE;

    &:hover {
        background: #4A7FFC;
        border-color: #4A7FFC;
    }
}

.goBack {
    margin-bottom: 15px;
    display: inline-flex;
    align-items: center;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #9594A9;

    &:before {
        content: "";
        margin-right: 7px;
        width: 12px;
        height: 9px;
        background: #9594A9;
        mask-image: url("../images/goBack.svg");
    }

    &:hover {
        color: #060438;
        text-decoration: none;

        &:before {
            background: #060438;
        }
    }
}

.breadcrumbs {
    margin-bottom: 12px;

    &__inner {
        padding: 0;
    }

    &__item {
        margin-right: 3px;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        color: #9594A9;

        &:before {
            margin-right: 3px;
            content: '/';
            width: auto;
        }

        &-link {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;
            color: #9594A9;

            &:hover {
                color: #060438;
                text-decoration: none;
            }
        }
    }
}

.grade {
    display: inline-flex;
    padding: 5px;
    border: 1px solid #060438;
    border-radius: 4px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #060438;
}

.price {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #060438;
}

.stack {
    margin: 0 -2px -4px;
    display: flex;
    flex-wrap: wrap;

    &-item {
        margin: 0 2px 4px;
        padding: 6px;
        background: #E8E8EB;
        border-radius: 4px;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        color: #656484;
    }
}

.tags {
    margin: 0 -2px -4px;
    display: flex;
    flex-wrap: wrap;

    &-item {
        margin: 0 2px 4px;
        padding: 6px;
        background: #E8E8EB;
        border-radius: 4px;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        color: #060438;

        &--primary {
            background: #E8ECF5;
        }
    }
}

.device-panel {
    & &__instruction {
        margin-bottom: 12px;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 800;
        font-size: 30px;
        line-height: 36px;
        color: #060438;
    }

    & &__btn {
        font-size: 16px;
        margin: 4px;
    }
}

.filter {

    &-trigger {
        display: none;
    }

    &-header {
        display: none;
    }

    &-block {
        margin-bottom: 32px;

        &:last-child {
            margin-bottom: 0;
        }

        &__header {
            position: relative;
            padding-right: 15px;
            font-family: 'Inter';
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 19px;
            color: #060438;
            cursor: pointer;

            &:after {
                content: "";
                position: absolute;
                top: 0;
                right: 0;
                width: 1em;
                height: 19px;
                display: inline-block;
                font-family: "advantshopfonts";
                font-style: normal;
                font-weight: normal;
                speak: none;
                text-decoration: inherit;
                text-align: center;
                font-variant: normal;
                text-transform: none;
                font-size: .6875rem;
                line-height: 19px;
                transition: all .1s ease-in;
            }
        }

        &--open & {
            &__header:after {
                transform: rotateZ(-90deg);
            }

            &__content {
                display: block;
            }
        }

        &__content {
            padding: 12px 0 0;
            display: none;

            &Item {
                margin-bottom: 8px;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }

    &-footer {
        display: none;
    }
}

.pagination {
    display: flex;
    margin: 0 -2px -4px;

    &__item {
        margin: 0 2px 4px;
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 16px;
        text-align: center;
        color: #060438;
        border: 1px solid #DEDDE3;
        border-radius: 4px;
        background: #FFFFFF;
        box-sizing: border-box;
        cursor: pointer;

        &--prev,
        &--next {
            position: relative;

            &:after {
                content: "";
                width: 12px;
                height: 12px;
                display: inline-block;
                font-family: "advantshopfonts";
                font-style: normal;
                font-weight: normal;
                text-decoration: inherit;
                text-align: center;
                font-variant: normal;
                text-transform: none;
                font-size: 12px;
                line-height: 12px;
            }
        }

        &--prev:after {
            transform: scale(-1);
        }


        &--active,
        &:hover {
            background: #004CFE;
            color: #FFFFFF;
            border-color: #004CFE;
        }
    }
}
