.goBack + .product {
    margin-top: 20px;
}

.product {
    display: flex;

    &-info {
        margin-right: 40px;
        flex: 0 0 260px;
        width: 260px;
        max-width: 260px;

        .gallery-block {
            padding-right: 0;
            margin-bottom: 16px;
        }

        &__item {
            margin-bottom: 8px;
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;
            color: #060438;

            &:last-of-type {
                margin-bottom: 0;
            }
        }

        &__button {
            margin-top: 16px;
            padding-right: 20px;
            padding-left: 20px;
        }
    }

    &-description {
        flex: 0 0 calc(100% - 300px);
        width: calc(100% - 300px);
        max-width: calc(100% - 300px);

        &__Content {
            max-width: 580px;
        }
    }

    &-name {
        margin-bottom: 16px;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 29px;
        color: #060438;
    }

    &-position {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        font-size: 30px;
        line-height: 36px;
        color: #060438;
    }
}

.product-infoBlock {
    margin-top: 32px;

    &__title {
        margin-bottom: 12px;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
        color: #060438;
    }

    &--grade {
        margin-top: 16px;
    }

    &--borderTop {
        padding-top: 32px;
        border-top: 1px solid #E8E8EB;
    }

    &--borderTop &__title {
        margin-bottom: 32px;
    }
}

.description {}

.projects {
    display: flex;
    flex-direction: column;

    &-item {
        margin-bottom: 32px;

        &:last-child {
            margin-bottom: 0;
        }

        &__name {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 17px;
            color: #060438;
        }

        &__position {
            margin-top: 12px;
            display: inline-flex;
            padding: 5px;
            border: 1px solid #060438;
            border-radius: 4px;
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;
            color: #060438;
        }

        &__description {
            margin-top: 12px;
        }

        &__date {
            margin-top: 12px;
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 21px;
            color: #9594A9;
        }
    }
}

.education {
    &-item {
        margin-bottom: 24px;

        &:last-child {
            margin-bottom: 0;
        }

        &__name {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 17px;
            color: #060438
        }

        &__position {
            margin-top: 4px;
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 24px;
            color: #060438;
        }

        &__date {
            margin-top: 4px;
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 21px;
            color: #9594A9;
        }
    }
}
