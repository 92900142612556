.products-view {
    margin-top: 20px;
    margin-right: -10px;
    margin-left: -10px;

    &-sort {
        margin: 0 -4px -8px;
        display: flex;
        flex-wrap: wrap;

        &__item {
            margin: 0 4px 8px;
            padding: 10px 12px;
            border: 1px solid #DEDDE3;
            background: #FFFFFF;
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;
            color: #060438;
            border-radius: 4px;
            cursor: pointer;

            &--active, &:hover {
                background: #DEDDE3;
            }
        }
    }

    .product-view {
        margin: 0 10px 20px;
        flex: 0 0 calc(50% - 20px);
        width: calc(50% - 20px);
        max-width: calc(50% - 20px);
    }
}
.product-view {
    padding: 20px;
    display: block;
    background: #FFFFFF;
    border: 1px solid #E8E8EB;
    border-radius: 4px;
    box-sizing: border-box;
    text-decoration: none !important;

    &__info {
        display: flex;

        &Image {
            flex: 0 0 81px;
            width: 81px;
            max-width: 81px;
            height: 81px;

            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
                object-position: center;
            }
        }

        &Content {
            padding-left: 16px;
            flex: 0 0 calc(100% - 81px);
            width: calc(100% - 81px);
            max-width: calc(100% - 81px);
            box-sizing: border-box;
        }
    }

    &__name {
        margin-bottom: 8px;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        color: #060438;
    }

    &__position {
        margin-bottom: 8px;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        color: #060438;
    }

    &__grade-and-price {
        display: flex;
        align-items: center;

        .grade {
            margin-right: 12px;
        }
    }

    &__stack {
        margin-top: 12px;
    }

    &__description {
        color: #000;
        margin-top: 12px;
        display: -webkit-box;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 7;
    }

    &__city {
        margin-top: 12px;
        font-family: 'Tahoma';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        color: #9594A9;
    }

    &-experience {
        padding-bottom: 15px;
        font-family: "Inter";
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 17px;
        color: #060438;
    }
}